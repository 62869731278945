<template>
  <div id="globalWallet">
    <header-nav :content="$t('pages_router_022')" />
    <div class="common-card-body">
      <div class="amount-total flex-align">
        <a-spin v-if="amountLoading" style="margin-left: 48px" />
        <div v-else>
          <div class="amount">{{ totalAmount }} USD</div>
          <div class="expliain">{{ $t('pages_wallet_001') }} ({{ $t('pages_wallet_002') }}{{ nowTime }})</div>
        </div>
      </div>
      <div class="table-part">
        <a-table
          :scroll="{ x: 890 }"
          :loading="loading"
          :pagination="false"
          :custom-header-row="() => { return {class: 'my-table-tr'}}"
          :columns="columns"
          :data-source="curList"
        >
          <template #headerCell="{ column }">
            <template v-if="column.key === 'freezeAmount'">
              <div class="flex-align">
                {{ column.title }}
                <a-tooltip :title="$t('pages_wallet_022')" placement="top">
                  <question-circle-filled class="ic-tip" />
                </a-tooltip>
              </div>
            </template>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'currency'">
              <div class="flex-align table-cur">
                <img style="width: 21px; height: 13px" :src="getCountryLogo(record.currency)">
                <span style="margin-left: 12px">{{ record.currency }}</span>
                <span style="margin-left: 8px">{{ record.currencyName }}</span>
              </div>
            </template>
            <template v-else-if="column.key === 'edit'">
              <a-space style="justify-content: center; gap: 0px">
                <a-button size="small" type="link" @click="handleRecharge(record.currency)">{{ $t('pages_wallet_008') }}</a-button>
                <a-divider type="vertical" />
                <a-button size="small" type="link" @click="$router.push('/exchange/apply')">{{ $t('pages_wallet_009') }}</a-button>
                <a-divider type="vertical" />
                <a-button size="small" type="link" @click="$router.push('/funds/outbounds')">{{ $t('pages_wallet_010') }}</a-button>
                <a-divider type="vertical" />
                <a-button size="small" type="link" @click="$router.push('/funds/inbounds')">{{ $t('pages_wallet_011') }}</a-button>
              </a-space>
            </template>
          </template>
        </a-table>
      </div>
    </div>
    <a-modal
      wrap-class-name="recharge-detail"
      :visible="visible"
      :title="$t('pages_wallet_020')"
      :mask-closable="false"
      @cancel="() => visible = false"
    >
      <div class="title">
        <info-circle-filled class="ic-info" />
        <span>{{ $t('pages_wallet_021') }}</span>
      </div>
      <div class="content">
        <div class="content-left">
          <div class="name">{{ $t('pages_wallet_012') }}:</div>
          <div class="name">{{ $t('pages_wallet_013') }}:</div>
          <div class="name">{{ $t('pages_wallet_014') }}:</div>
          <div class="name">{{ $t('pages_wallet_023') }}:</div>
          <div class="name">{{ $t('pages_wallet_015') }}:</div>
          <div class="name">{{ $t('pages_wallet_016') }}:</div>
          <div class="name">{{ $t('pages_wallet_017') }}:</div>
          <div class="name">{{ $t('pages_wallet_018') }}:</div>
          <div class="name">{{ $t('pages_wallet_019') }}:</div>
          <div class="name">{{ $t('pages_wallet_024') }}:</div>
        </div>
        <div class="content-right">
          <div class="name flex-align">
            <a-tooltip :title="bankName"><span class="area">{{ bankName }}</span></a-tooltip>
            <copy-outlined v-if="bankName" class="ic-copy" @click="copyToClipboard(bankName)" />
          </div>
          <div class="name flex-align">
            <a-tooltip :title="rechargeInfo.bankCountry"><span class="area">{{ rechargeInfo.bankCountry }}</span></a-tooltip>
            <copy-outlined v-if="rechargeInfo.bankCountry" class="ic-copy" @click="copyToClipboard(rechargeInfo.bankCountry )" />
          </div>
          <div class="name flex-align">
            <a-tooltip :title="rechargeInfo.swiftCode"><span class="area">{{ rechargeInfo.swiftCode }}</span></a-tooltip>
            <copy-outlined v-if="rechargeInfo.swiftCode" class="ic-copy" @click="copyToClipboard(rechargeInfo.swiftCode)" />
          </div>
          <div class="name flex-align">
            <a-tooltip :title="rechargeInfo.bankAddress"><span class="area">{{ rechargeInfo.bankAddress }}</span></a-tooltip>
            <copy-outlined v-if="rechargeInfo.bankAddress" class="ic-copy" @click="copyToClipboard(rechargeInfo.bankAddress)" />
          </div>
          <div class="name flex-align">
            <a-tooltip :title="rechargeInfo.bankAcctName"><span class="area">{{ rechargeInfo.bankAcctName }}</span></a-tooltip>
            <copy-outlined v-if="rechargeInfo.bankAcctName" class="ic-copy" @click="copyToClipboard(rechargeInfo.bankAcctName)" />
          </div>
          <div class="name flex-align">
            <a-tooltip :title="rechargeInfo.bankAcctCode"><span class="area">{{ rechargeInfo.bankAcctCode }}</span></a-tooltip>
            <copy-outlined v-if="rechargeInfo.bankAcctCode" class="ic-copy" @click="copyToClipboard(rechargeInfo.bankAcctCode)" />
          </div>
          <div class="name flex-align">
            <a-tooltip :title="rechargeInfo.bankBranchCode"><span class="area">{{ rechargeInfo.bankBranchCode }}</span></a-tooltip>
            <copy-outlined v-if="rechargeInfo.bankBranchCode" class="ic-copy" @click="copyToClipboard(rechargeInfo.bankBranchCode)" />
          </div>
          <div class="name flex-align">
            <a-tooltip :title="rechargeInfo.bankAcctNo"><span class="area">{{ rechargeInfo.bankAcctNo }}</span></a-tooltip>
            <copy-outlined v-if="rechargeInfo.bankAcctNo" class="ic-copy" @click="copyToClipboard(rechargeInfo.bankAcctNo)" />
          </div>
          <div class="name flex-align">
            <a-tooltip :title="rechargeInfo.merchantNo"><span class="area">{{ rechargeInfo.merchantNo }}</span></a-tooltip>
            <copy-outlined v-if="rechargeInfo.merchantNo" class="ic-copy" @click="copyToClipboard(rechargeInfo.merchantNo)" />
          </div>
          <div class="name flex-align">
            <a-tooltip :title="rechargeInfo.payeeAddress"><span class="area">{{ rechargeInfo.payeeAddress }}</span></a-tooltip>
            <copy-outlined v-if="rechargeInfo.payeeAddress" class="ic-copy" @click="copyToClipboard(rechargeInfo.payeeAddress)" />
          </div>
        </div>
      </div>

      <template #footer>
        <a-button
          type="primary"
          @click="() => visible = false"
        >{{ $t('common_text_024') }}</a-button>
      </template>

    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import HeaderNav from '@/components/header-nav'
import { _pay, _home } from '@/api'
import i18n from '@/locale/index'
import moment from 'moment'
import { getCountryLogo, getTradeStatusName, copyToClipboard, parseMoney } from '@/util'
export default {
  name: 'GlobalWallet',
  components: {
    'header-nav': HeaderNav
  },
  setup() {
    let timer = null
    const store = useStore()
    const state = reactive({
      loading: true,
      amountLoading: true,
      visible: false,
      bankName: '',
      bankCode: '',
      rechargeInfo: {},
      totalAmount: 0,
      nowTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      curList: [],
      columns: [
        { key: 'currency', title: i18n.global.t('pages_wallet_003'), width: 180 },
        { key: 'amount', dataIndex: 'amountText', title: i18n.global.t('pages_wallet_004'), width: 150, ellipsis: true },
        { key: 'freezeAmount', dataIndex: 'freezeAmountText', title: i18n.global.t('pages_wallet_005'), width: 150, ellipsis: true },
        // { key: 'acctNo', dataIndex: 'acctNo', title: i18n.global.t('pages_wallet_006'), minWidth: 200, ellipsis: true },
        { key: 'acctStatus', dataIndex: 'acctStatusText', title: i18n.global.t('pages_wallet_007'), ellipsis: true, width: 100 },
        { key: 'edit', title: i18n.global.t('common_text_001'), align: 'center', fixed: 'right', width: localStorage.getItem('locale-language') === 'en' ? 508 : 300 }
      ]
    })

    onUnmounted(() => {
      clearInterval(timer)
    })

    const handleRecharge = async (currency) => {
      state.visible = true
      state.rechargeInfo = {}
      const res = await _pay.getBankSuppDetail({ currency, bankCode: state.bankCode })
      if (res.data.succ) {
        if (res.data.data && res.data.data.bankAcctNo) {
          state.rechargeInfo = res.data.data
          state.rechargeInfo.merchantNo = store.getters.getUserInfo.merchantNo
        }
      }
    }

    const loadWalletRatio = async () => {
      state.amountLoading = true
      clearInterval(timer)
      const res = await _home.getWalletRatio()
      if (res.data.succ) {
        state.totalAmount = parseMoney(res.data.data)
        state.amountLoading = false
      } else {
        timer = setInterval(() => {
          loadWalletRatio()
        }, 5000)
      }
    }

    const loadRechargeSup = async () => {
      const res = await _pay.getRechargeSup()
      if (res.data.succ) {
        state.bankName = res.data.data.bankName
        state.bankCode = res.data.data.bankCode
      }
    }

    const loadCurList = async () => {
      state.loading = true
      const res = await _pay.getCurAccount()
      if (res.data.succ) {
        state.curList = res.data.data.map(item => {
          return Object.assign({}, item, {
            acctStatusText: getTradeStatusName(item.acctStatus, 'userStatus'),
            acctTypeText: getTradeStatusName(item.acctType, 'acctType'),
            amountText: item.amount ? parseMoney(item.amount, item.currency) : '0.00',
            totalAmountText: item.totalAmount ? parseMoney(item.totalAmount) : '0.00',
            freezeAmountText: item.freezeAmount ? parseMoney(item.freezeAmount) : '0.00'
          })
        })
      }
      state.loading = false
    }

    loadCurList()
    loadWalletRatio()
    loadRechargeSup()

    return {
      ...toRefs(state),
      getCountryLogo,
      loadRechargeSup,
      handleRecharge,
      copyToClipboard
    }
  }
}
</script>
<style lang="less" scoped>
#globalWallet {
  .common-card-body {
    .amount-total {
      text-align: left;
      padding: 0 36px 12px 36px;
      height: 70px;
      .amount {
        font-size: 24px;
        font-weight: 600;
        color: @main-color;
      }
      .expliain {
        font-size: 12px;
        color: #333;
      }
    }
    .table-part {
      margin-top: 12px;
      .ic-tip {
        margin-left: 5px;
        font-size: 16px;
        color: @main-color;
      }
      .table-cur {
        font-weight: 500;
        color: @main-color;
      }
    }
  }
}
.recharge-detail {
  .title {
    .flex-align;
    font-size: 15px;
    font-weight: 500;
    color: @main-color;
    margin: -12px 0 24px 0;
    .ic-info {
      font-size: 16px;
      margin-right: 5px;
    }
  }
  .content {
    display: flex;
    overflow: hidden;
    .content-left {
      flex: 1;
      background: @table-th-color;
      padding: 0 15px;
      text-align: center;
      border-radius: 8px 0 0 8px;
      .name {
        height: 50px;
        line-height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .content-right {
      flex: 3;
      border: 1px solid #EFEFEF;
      border-radius: 0 8px 8px 0;
      overflow: hidden;
      .name {
        height: 50px;
        line-height: 50px;
        padding: 0 24px;
        border-bottom: 1px solid #EFEFEF;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .area {
          flex: 20;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .ic-copy {
          color: @main-color;
          margin-left: auto;
          cursor: pointer;
          flex: 1;
        }
      }
      .name:last-child {
        border: 0;
      }
    }
  }
}
</style>

